<template>
  <div class="group-projects" v-loading="loading">
    <div class="flex">
      <div>
        <div
          class="bg-[#F7F7F7] border border-solid border-[#F7F7F7] w-[400px] p-2 flex items-center justify-between"
        >
          <p>Modul</p>
        </div>
        <div
          v-if="courses.length"
          v-for="(element, index) in filterCourses"
          :key="element.id"
          class="w-[400px] border border-solid border-[#F7F7F7]"
        >
          <button
            @click="
              (active_course_id = element.id), (selectedCourseElement = element)
            "
            class="p-2 text-[#004466] font-[600] w-full text-left border-0 border-l-2 border-solid border-transparent"
            :class="{
              active: element.id == active_course_id,
            }"
          >
            {{ index + 1 }}.{{
              lang === "uz"
                ? element?.course_element_uz
                : element?.course_element_ru
            }}
            <i
              v-for="el in element?.process_details"
              :key="el"
              class="fa-solid fa-check float-right text-green-500"
            ></i>
          </button>
        </div>
        <p v-else class="text-xl font-bold text-[#b3b3b3]">Course not found</p>
        <el-pagination
          class="border border-solid border-[#F7F7F7] rounded"
          layout="prev,pager,next"
          :page-count="lastModule"
          :background="false"
          v-model:current-page="currentPage"
          :hide-on-single-page="true"
        />
      </div>
      <el-tabs v-model="activeName" class="w-full demo-tabs" type="card">
        <el-tab-pane :label="$t('about-tasks')" name="methodology">
          <div class="px-5">
            <div class="flex items-center justify-between">
              <p class="text-2xl font-[600] text-[#004466]">
                {{
                  lang === "uz"
                    ? selectedCourseElement?.course_element_uz
                    : selectedCourseElement?.course_element_ru
                }}
              </p>
              <button
                v-if="!selectedCourseElement?.process_details?.length"
                @click="startLesson"
                class="px-2 py-1 bg-[#FC6736] flex items-center gap-1 text-md font-medium text-[#fff] rounded"
              >
                <i class="fa-solid fa-play"></i>Boshlash
              </button>
              <button
                v-else
                @click="startLesson('repeated')"
                class="px-2 py-1 border border-solid border-[#FC6736] flex items-center gap-1 text-md font-medium text-[#FC6736] rounded"
              >
                <i class="fa-solid fa-repeat"></i>Takrorlash
              </button>
            </div>
            <div v-if="active_course_id">
              <MethodologyComponent
                :methodology="homeworkDetails?.methodologies"
              />
              <HomeworkComponent :homework="homeworkDetails?.project" />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('tasks')" name="tasks">
          <div class="px-5">
            <div class="flex items-center justify-between">
              <p class="text-2xl font-[600] text-[#004466]">
                {{
                  lang === "uz"
                    ? selectedCourseElement?.course_element_uz
                    : selectedCourseElement?.course_element_ru
                }}
              </p>
              <button
                v-if="!selectedCourseElement?.process_details?.length"
                @click="startLesson('completed')"
                class="px-2 py-1 bg-[#FC6736] flex items-center gap-1 text-md font-medium text-[#fff] rounded"
              >
                <i class="fa-solid fa-play"></i>{{ $t("Adminstration.start") }}
              </button>
              <button
                v-else
                @click="startLesson('repeated')"
                class="px-2 py-1 border border-solid border-[#FC6736] flex items-center gap-1 text-md font-medium text-[#FC6736] rounded"
              >
                <i class="fa-solid fa-repeat"></i>{{ $t("repeat") }}
              </button>
            </div>
          </div>
          <div class="px-5">
            <table class="w-full rounded overflow-hidden mt-5">
              <thead>
                <tr class="bg-[#F7F7F7] text-sm text-[#666]">
                  <th class="text-left p-2">O'quvchilar ro'yhati</th>
                  <th class="p-2">File</th>
                  <th class="p-2">Link</th>
                  <th class="p-2">Status</th>
                  <th class="p-2">Baho</th>
                  <th class="p-2">Izoh</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class=""
                  v-for="(std, i) in active_course_answers?.students"
                  :key="std.id"
                >
                  <td class="p-2 text-[#004466] font-xl font-[600]">
                    {{ i + 1 }}.{{ std.first_name }} {{ std.last_name }}
                  </td>
                  <td class="p-2 text-center">
                    <div v-if="std.answer">
                      <a
                        v-if="std.answer.project_answer?.file"
                        :href="medialink + std.answer.project_answer?.file"
                        class="text-[#35BCFF] underline"
                        download
                        target="_blank"
                        >File</a
                      >
                      <p v-else>-</p>
                    </div>
                  </td>
                  <td class="p-2 text-center">
                    <div v-if="std.answer">
                      <a
                        v-if="std.answer.project_answer?.link"
                        :href="std.answer.project_answer?.link"
                        class="text-[#35BCFF] underline"
                        target="_blank"
                        >Link</a
                      >
                      <p v-else>-</p>
                    </div>
                  </td>
                  <td class="p-2 text-center text-sm text-[#004466]">
                    {{ std.answer?.status }}
                  </td>
                  <td class="p-2 text-center text-sm text-[#004466]">
                    {{ std.answer?.mark }}
                  </td>
                  <td
                    class="p-2 text-center text-sm text-[#004466] max-w-[300px]"
                  >
                    {{ std.answer?.feedback }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { getMarks, getDetails } from "./api/get-projects.api";
import HomeworkComponent from "./components/HomeworkComponent.vue";
import MethodologyComponent from "./components/MethodologyComponent.vue";
import store from "@/store";
import api from "@/apiConfig/api";
import { medialink } from "@/apiConfig/medialink";
import apiV2 from "@/apiConfig/api-v2";
import { ElMessage } from "element-plus";

// Refs and reactive state
const groupId = computed(() => store.state.user.teacherActiveGrId);
const active_course_id = ref(null);
const courses = ref([]);
const active_course_answers = ref(null);
const homeworkDetails = ref(null);
const activeName = ref("tasks");
const selectedCourseElement = ref(null);
const currentPage = ref(1);
let lang = localStorage.getItem("lang");
if (lang == "en") lang = "uz";
const loading = ref(false);

const getStudentMarks = async () => {
  loading.value = true;
  if (courses.value?.length > 0) {
    active_course_answers.value = await getMarks(
      groupId.value,
      active_course_id.value
    ).then((res) => {
      homeworkDetails.value = res.data.course_element;
      return res.data.status_code === 400 ? "-" : res.data || "-";
    });
  }

  loading.value = false;
};

const getGroupDetails = async () => {
  loading.value = true;
  courses.value = await getDetails(groupId.value)
    .then((res) => res.data)
    .catch(() => []);
  loading.value = false;
};

const lastModule = computed(() => store.state.user.courseLastModule);

watch(
  () => groupId.value,
  async (val) => {
    if (val && active_course_id.value) {
      getStudentMarks();
    }

    if (val) {
      await getGroupDetails();
      findLastLesson();
    }
  }
);

watch(
  () => active_course_id.value,
  (val) => {
    if ((val, groupId.value)) {
      getStudentMarks();
    }
  }
);

watch(
  () => currentPage.value,
  (val) => {
    if (val && groupId.value) {
      getGroupDetails();
    }
  }
);
onMounted(async () => {
  if (groupId.value && !courses.value.length) {
    await getGroupDetails();

    findLastLesson();
  }
});
const findLastLesson = async () => {
  const lesson = courses.value.findLast((c) => c.process_details?.length);
  if (lesson?.id) {
    active_course_id.value = lesson.id;
    selectedCourseElement.value = lesson;
    currentPage.value = lesson.module;
  } else if (courses.value.length) {
    active_course_id.value = courses.value[0].id;
    selectedCourseElement.value = courses.value[0];
    currentPage.value = 1;
  }
};
const startLesson = (status) => {
  apiV2
    .post("/groups/mark_lesson", {
      group_id: groupId.value,
      course_element_id: active_course_id.value,
      status: status,
    })
    .then(async () => {
      ElMessage.success(status);
      await getGroupDetails();
      findLastLesson();
    })
    .catch((err) => {
      ElMessage.error(err.response?.data?.detail);
    });
};

const filterCourses = computed(() =>
  courses.value.filter((c) => c.module === currentPage.value)
);
</script>
<style lang="scss">
.group-projects {
  .active {
    color: #35bcff;
    border-color: #35bcff;
    background-color: #e5f6ff;
  }
}
</style>
