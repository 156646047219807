<template>
  <nav
    class="sidebar text-center py-10"
    :class="{
      'axo-sidebar': role === 'axo',
    }"
  >
    <div class="sidebar__link-wrapper" v-if="mainPage">
      <router-link :to="'/' + role + '-dashboard'" class="sidebar__link">
        <i class="fa-solid fa-house"></i>
        <p>{{ $t("dashboard") }}</p>
      </router-link>
    </div>
    <div class="sidebar__link-wrapper sidebar__link--back" v-else>
      <button @click="router.go(-1)" class="sidebar__link">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
    </div>
    <component :is="role + '-sidebar'" />
  </nav>
</template>

<script>
import { groupIdMixin } from "@/mixins/get-group-id.mixin";
import adminSidebar from "./sidebars/adminSidebar.vue";
import auditSidebar from "./sidebars/auditSidebar.vue";
import academySidebar from "./sidebars/academySidebar.vue";
import { onBeforeMount, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import financeSidebar from "./sidebars/financeSidebar.vue";
import instructorSidebar from "./sidebars/instructorSidebar.vue";
import hunterSidebar from "./sidebars/hunterSidebar.vue";
import axoSidebar from "./sidebars/axoSidebar.vue";
import tutorSidebar from "./sidebars/tutorSidebar.vue";
import { decryptText } from "@/utils/cryptFunc";
export default {
  mixins: [groupIdMixin],
  components: {
    adminSidebar,
    auditSidebar,
    financeSidebar,
    academySidebar,
    instructorSidebar,
    axoSidebar,
    hunterSidebar,
    tutorSidebar,
  },
  setup() {
    const role = ref(decryptText(localStorage.getItem("role")));
    const mainPage = ref(true);

    const route = useRoute();

    const router = useRouter();

    const checkRoute = (val) => {
      if (val.name.includes("main")) {
        mainPage.value = true;
      } else {
        mainPage.value = false;
      }
    };

    onBeforeMount(() => {
      checkRoute(route);
    });

    watch(route, (val) => {
      checkRoute(val);
      role.value = decryptText(localStorage.getItem("role"));
    });

    return { role, mainPage, router };
  },
};
</script>
