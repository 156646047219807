import SignInView from "../views/SigninView.vue";
import ProjectsView from "../modules/Teachers/pages/Projects/ProjectsView.vue";
import NotFound from "../components/NotFound.vue";
import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";
import store from "@/store";
import { decryptText } from "@/utils/cryptFunc";

const routes = [
  {
    name: "signin",
    component: SignInView,
    path: "/",
    meta: {
      layout: "auth",
      auth: false,
    },
  },
  {
    path: "/teacher-dashboard",
    name: "teacher-main",
    component: () =>
      import("../modules/Teachers/pages/TeacherDash/TeacherDash.vue"),
    meta: {
      auth: true,
      layout: "main",
      role: ["teacher", "academy"],
    },
  },

  {
    path: "/group-projects/:group_id",
    name: "teacher-projects",
    component: ProjectsView,
    meta: {
      auth: true,
      layout: "main",
      role: ["teacher", "instructor", "academy"],
    },
  },

  {
    path: "/group-attendance/:group_id",
    name: "group-attendance",
    component: () =>
      import(
        "../modules/Teachers/pages/StudentsAttendance/StudentsAttendance.vue"
      ),
    meta: {
      auth: true,
      layout: "main",
      role: [
        "teacher",
        "instructor",
        "admin",
        "academy",
        "branchManager",
        "hunter",
        "finance",
      ],
    },
  },

  {
    path: "/teacher-examine",
    name: "teacher-examine",
    component: () => import("../modules/Examiner/ExamineView.vue"),
    meta: {
      auth: true,
      layout: "main",
      role: ["teacher", "instructor", "academy"],
    },
  },
  {
    path: "/teacher-profile",
    name: "teacher-profile",
    component: () =>
      import("@/modules/Teachers/pages/TeacherProfile/TeacherProfile.vue"),
    meta: {
      auth: true,
      layout: "main",
      role: ["teacher"],
    },
  },

  {
    path: "/teacher-group/:group_id",
    name: "teacher-group",
    component: () =>
      import("../modules/Teachers/pages/TeacherGroup/TeacherGroup.vue"),
    meta: {
      auth: true,
      layout: "main",
      role: ["teacher", "academy", "admin"],
    },
  },
  {
    path: "/tutor-dashboard",
    name: "tutor-main",
    component: () =>
      import("../modules/Tutor/pages/TutorDashboard/TutorDashboard.vue"),
    meta: {
      auth: true,
      layout: "main",
      role: ["tutor"],
    },
  },

  {
    path: "/academy-dashboard",
    name: "academy-main",
    component: () => import("../modules/Teachers/pages/MainPage/MainPage.vue"),
    meta: {
      auth: true,
      layout: "main",
      role: ["tutor", "academy"],
    },
  },
  {
    path: "/group-examine-date",
    name: "group-examine-date",
    component: () =>
      import("../modules/Teachers/pages/GroupExamineDate/GroupExamineDate.vue"),
    meta: {
      auth: true,
      layout: "main",
      role: ["teacher", "instructor", "academy"],
    },
  },
  {
    path: "/academy-teacher-list",
    name: "mentors-list",
    component: () =>
      import("../modules/Academy/pages/MentorsList/MentorsList.vue"),
    meta: {
      auth: true,
      layout: "main",
      role: ["admin", "academy", "teacher", "branchManager"],
    },
  },
  {
    path: "/teacher-info/:id",
    name: "mentor-info",
    component: () =>
      import("../modules/Academy/pages/MentorInfo/MentorInfo.vue"),
    meta: {
      auth: true,
      layout: "main",
      role: ["admin", "academy", "teacher", "branchManager", "finance"],
    },
  },
  {
    path: "/teacher-statistics",
    name: "teacher-main-statistics",
    component: () =>
      import("../modules/Academy/pages/TeacherStat/TeacherStatistics.vue"),
    meta: {
      auth: true,
      layout: "main",
      role: ["admin", "academy", "branchManager"],
      manager: "academy",
    },
  },
  {
    path: "/teacher-audit",
    name: "teacher-qa",
    component: () =>
      import("../modules/Academy/pages/TeacherQaAudit/TeachersAudit.vue"),
    meta: {
      auth: true,
      layout: "main",
      role: ["academy"],
      manager: "academy",
    },
  },
  {
    path: "/teacher-audit-form",
    name: "teacher-qa-form",
    component: () =>
      import(
        "../modules/Academy/features/TeachersAudit/components/TeachersAuditForm.vue"
      ),
    meta: {
      auth: true,
      layout: "main",
      role: ["academy"],
      manager: "academy",
    },
  },
  {
    path: "/teachers-lessons-schedule",
    name: "teachers-lessons-schedule",
    component: () =>
      import("../modules/Academy/pages/LessonsSchedule/LessonsSchedule.vue"),
    meta: {
      auth: true,
      layout: "main",
      role: ["academy"],
      manager: "academy",
    },
  },
  {
    path: "/teacher-audit-statistics",
    name: "teacher-audit-statistics",
    component: () =>
      import(
        "../modules/Academy/widgets/AcademyStatistics/AcademyStatistics.vue"
      ),
    meta: {
      auth: true,
      layout: "main",
      role: ["academy"],
      manager: "academy",
    },
  },
  {
    path: "/teachers-grading",
    name: "teacher-grading",
    component: () =>
      import("../modules/Academy/pages/TeachersGrading/UI/TeachersGrading.vue"),
    meta: {
      auth: true,
      layout: "main",
      role: ["academy"],
      manager: "academy",
    },
  },
  {
    path: "/tutors-list",
    name: "tutors-list",
    component: () =>
      import("../modules/Academy/pages/TutorsList/TutorsList.vue"),
    meta: {
      auth: true,
      layout: "main",
      role: ["teacher"],
      // role: ["academy"],
      manager: "academy",
    },
  },
  {
    path: "/generate-certificate",
    name: "generate-certificate",
    component: () =>
      import(
        "../modules/Academy/pages/GenerateCertificate/GenerateCertificate.vue"
      ),
    meta: {
      auth: true,
      layout: "main",
      role: ["academy"],
      manager: "academy",
    },
  },
  // {
  //   path: "/group-projects/:group_id/mark/:student_id/:project_id",
  //   name: "teachers-projects-mark",
  //   component: ProjectsMarkView,
  //   meta: {
  //     auth: true,
  //     layout: "main",
  //   },
  // },
  {
    path: "/call-dashboard",
    component: () => import("@/modules/Sales/pages/SalesMain/UI/SalesMain.vue"),
    name: "call-main",
    meta: {
      layout: "main",
      auth: true,
      role: ["call"],
    },
  },
  {
    path: "/hunter-dashboard",
    component: () =>
      import("@/modules/Hunter/pages/HunterDashboard/UI/HunterDashboard.vue"),
    name: "hunter-main",
    meta: {
      layout: "main",
      auth: true,
      role: ["hunter"],
    },
  },
  {
    path: "/hunter-lessons-schedule",
    component: () =>
      import(
        "@/modules/Hunter/pages/HunterLessonsSchedule/HunterLessonsSchedule.vue"
      ),
    name: "hunter-lessons-schedule-main",
    meta: {
      layout: "main",
      auth: true,
      role: ["hunter"],
    },
  },

  {
    name: "admin-main",
    component: () => import("@/modules/Admin/pages/AdminMain/UI/AdminMain.vue"),
    path: "/admin-dashboard",
    meta: {
      layout: "main",
      auth: true,
      role: ["admin", "branchManager"],
    },
  },
  {
    name: "admin-main-form",
    component: () => import("@/modules/Admin/pages/AdminForm/AdminForm.vue"),
    path: "/admin-form",
    meta: {
      layout: "main",
      auth: true,
      role: ["admin", "branchManager"],
    },
  },
  {
    name: "admin-coins",
    component: () => import("@/modules/Admin/pages/AdminCoins/AdminCoins.vue"),
    path: "/admin-coins",
    meta: {
      layout: "main",
      auth: true,
      role: ["admin", "branchManager"],
    },
  },

  {
    name: "admin-mentors-main",
    component: () =>
      import("@/modules/Admin/pages/MentorsList/MentorsList.vue"),
    path: "/admin-mentors",
    meta: {
      layout: "main",
      auth: true,
      role: ["admin", "branchManager"],
    },
  },

  {
    name: "admin-groups-main",
    component: () => import("../modules/Admin/pages/GroupList/GroupList.vue"),
    path: "/admin-groups",
    meta: {
      layout: "main",
      auth: true,
      role: ["admin", "branchManager"],
    },
  },
  {
    name: "admin-main-lessons-table",
    component: () =>
      import("../modules/Administration/pages/LessonsTable/LessonsTable.vue"),
    path: "/admin-lessons-table",
    meta: {
      layout: "main",
      auth: true,
      role: ["admin", "hunter", "branchManager"],
    },
  },
  {
    name: "admin-main-sms",
    component: () => import("../modules/Administration/pages/Sms/SmsPage.vue"),
    path: "/admin-sms",
    meta: {
      layout: "main",
      auth: true,
      role: ["admin", "hunter", "branchManager"],
    },
  },

  {
    name: "student-profile",
    component: () =>
      import("../modules/Admin/pages/StudentProfile/StudentProfile.vue"),
    path: "/student-profile/:student_id",
    meta: {
      layout: "main",
      auth: true,
      role: ["admin", "branchManager", "hunter", "finance"],
    },
  },
  {
    name: "student-certificate-generate",
    component: () =>
      import(
        "../modules/Admin/pages/StudentCertificateGenerate/StudentCertificateGenerate.vue"
      ),
    path: "/student-certificate-generate/:student_id",
    meta: {
      layout: "main",
      auth: true,
      role: ["admin"],
    },
  },
  {
    name: "groups-openning-main",
    component: () =>
      import("../modules/Administration/pages/GroupsOpening/GroupsOpening.vue"),
    path: "/groups-opening",
    meta: {
      layout: "main",
      auth: true,
      role: ["hunter", "admin", "branchManager"],
    },
  },

  {
    name: "admin-main-space-shop",
    component: () =>
      import("../modules/Administration/pages/SpaceShop/SpaceShop.vue"),
    path: "/admin-space-shop",
    meta: {
      layout: "main",
      auth: true,
      role: ["admin", "branchManager"],
    },
  },
  {
    name: "admin-main-students",
    component: () =>
      import("../modules/Administration/pages/StudentsList/StudentsList.vue"),
    path: "/admin-students",
    meta: {
      layout: "main",
      auth: true,
      role: ["admin", "branchManager"],
    },
  },
  {
    name: "admin-new-leads-main",
    component: () => import("../modules/Admin/pages/NewLeads/NewLeads.vue"),
    path: "/admin-new-leads",
    meta: {
      layout: "main",
      auth: true,
      role: ["admin", "branchManager"],
    },
  },

  {
    path: "/instructor-dashboard",
    name: "instructor-main",
    component: () => import("../modules/Instructor/InstructorView.vue"),
    meta: {
      layout: "main",
      auth: true,
      role: ["instructor"],
    },
  },

  {
    path: "/instructor-groups",
    name: "instructor-groups",
    component: () =>
      import(
        "../modules/Instructor/pages/InstructorGroups/InstructorGroups.vue"
      ),
    meta: {
      layout: "main",
      auth: true,
      role: ["instructor"],
    },
  },

  {
    path: "/examiner-dashboard",
    name: "examiner-main",
    component: () => import("../modules/Examiner/ExamineView.vue"),
    meta: {
      layout: "main",
      auth: true,
      role: ["examiner", "academy"],
    },
  },
  {
    path: "/examiner-table/:group_id/:group_name",
    name: "examiner-table",
    component: () =>
      import("../modules/Examiner/pages/ExaminerTable/ExaminerTable.vue"),
    meta: {
      layout: "main",
      auth: true,
      role: ["examiner", "academy"],
    },
  },
  {
    path: "/finance-dashboard",
    name: "finance-main",
    component: () =>
      import("../modules/Finance/pages/MainFinance/UI/FinanceDashboard.vue"),
    meta: {
      layout: "main",
      auth: true,
      role: ["finance"],
    },
  },
  {
    path: "/finance-mentors",
    name: "finance-mentors-main",
    component: () =>
      import("../modules/Finance/pages/MainFinance/UI/FinanceDashboard.vue"),
    meta: {
      layout: "main",
      auth: true,
      role: ["finance"],
    },
  },
  {
    path: "/finance-branches",
    name: "finance-branches-main",
    component: () =>
      import("../modules/Finance/pages/FinanceBranches/FinanceBranches2.vue"),
    meta: {
      layout: "main",
      auth: true,
      role: ["finance"],
    },
  },
  {
    path: "/finance-settings",
    name: "finance-settings-main",
    component: () =>
      import("../modules/Finance/pages/FinanceSettings/FinanceSettings.vue"),
    meta: {
      layout: "main",
      auth: true,
      role: ["finance"],
    },
  },
  {
    path: "/finance-employee-info/:user_id",
    name: "finance-employee-info",
    component: () =>
      import("../modules/Finance/pages/EmployeeInfo/UI/EmployeeInfo.vue"),
    meta: {
      layout: "main",
      auth: true,
      role: ["finance"],
    },
  },
  {
    path: "/salary-info/group_id=:group_id",
    name: "finance-salary-info",
    component: () =>
      import("../modules/Finance/widgets/SalaryInfo/SalaryInfo.vue"),
    meta: {
      layout: "main",
      auth: true,
      role: ["finance"],
    },
  },

  {
    path: "/marketing-dashboard",
    name: "marketing-main",
    component: () =>
      import("../modules/Marketing/pages/Marketing/TheMarketing.vue"),
    meta: {
      layout: "main",
      auth: true,
      role: ["marketing"],
    },
  },

  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: NotFound,
    meta: {
      layout: "auth",
      auth: false,
    },
  },
  {
    path: "/axo-dashboard",
    name: "axo-main",
    component: () =>
      import("../modules/AXO/pages/AxoDashboard/AxoDashboard2.vue"),
    meta: {
      layout: "main",
      auth: true,
      role: ["axo"],
    },
  },
  {
    path: "/axo-dashboard2",
    name: "axo-main-2",
    component: () =>
      import("../modules/AXO/pages/AxoDashboard/AxoDashboard2.vue"),
    meta: {
      layout: "main",
      auth: true,
      role: ["axo"],
    },
  },

  {
    path: "/moderator-dashboard",
    name: "moderator-main",
    component: () =>
      import("../modules/BlogModerator/pages/ModeratorMain/ModeratorMain.vue"),
    meta: {
      layout: "main",
      auth: true,
      role: ["moderator"],
    },
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  const requiredAuth = to.meta.auth;
  const userRole = decryptText(localStorage.getItem("role"));
  const isSuperUser = localStorage.getItem("superuser") || false;

  const isManager = localStorage.getItem("isManager") || false;

  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("access_token")}`;

  if (
    !!isSuperUser ||
    (requiredAuth &&
      store.getters["auths/isAuth"] &&
      to.meta.role.includes(userRole) &&
      to.meta?.manager &&
      isManager)
  ) {
    next();
  } else if (
    requiredAuth &&
    store.getters["auths/isAuth"] &&
    userRole &&
    !to.meta.role.includes(userRole) &&
    isManager !== to.meta?.manager
  ) {
    next({ name: `${userRole}-main` });
  } else if (requiredAuth && !store.getters["auths/isAuth"]) {
    next("/");
  } else if (!to.meta.manager) {
    next();
  } else {
    next({ name: `${userRole}-main` });
  }
});
export default router;
