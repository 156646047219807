<template></template>

<script>
import { useRoute } from "vue-router";
import { ref, watch, onBeforeMount } from "vue";
export default {
  setup() {
    const isShowRoutes = ref(false);
    const route = useRoute();
    const group_id = ref(localStorage.getItem("group_id"));

    watch(route, () => {
      isShowRoutes.value =
        route.name !== "instructor-main" && route.name !== "instructor-groups"
          ? true
          : false;

      group_id.value = localStorage.getItem("group_id");
    });
    onBeforeMount(() => {
      isShowRoutes.value =
        route.name !== "instructor-main" && route.name !== "instructor-groups"
          ? true
          : false;
    });
    return {
      group_id,
      isShowRoutes,
    };
  },
};
</script>

<style></style>
