<template>
  <header class="header">
    <div class="header-container flex justify-between items-center">
      <router-link :to="'/' + role + '-dashboard'">
        <img class="header-logo" src="@/assets/img/logo-white.png" alt="logo" />
      </router-link>

      <div class="flex gap-3">
        <div
          class="border-0 border-r-[1px] border-solid border-white pr-2 flex items-center"
        >
          <router-link
            v-if="role === 'teacher'"
            class="text-white font-bold"
            to="/teacher-profile"
            >{{ firstName }} {{ lastName }}
            <i class="fa-solid fa-angle-right text-xs"></i>
          </router-link>
          <p v-else class="text-white font-bold">
            {{ firstName }} {{ lastName }}
          </p>
        </div>
        <div class="border-0 border-r-[1px] border-solid border-white pr-3">
          <el-select
            v-model="selectedLanguage"
            class="!w-[70px]"
            placeholder="Select Language"
            @change="changeLanguage"
          >
            <el-option
              v-for="lang in langOptions"
              :key="lang.value"
              :label="lang.label"
              :value="lang.value"
            />
          </el-select>
        </div>
        <div>
          <div
            v-if="role === 'hunter' || role === 'finance'"
            class="border-0 border-r-[1px] border-solid border-grayScale300 pr-3"
          >
            <el-select
              v-model="$store.state.branches.active_branch"
              class="w-[180px]"
              @change="branchSelected"
            >
              <el-option
                v-for="branch in $store.state.branches.branch_list"
                :key="branch.id"
                :label="branch.title"
                :value="branch.id"
              />
            </el-select>
          </div>
          <div
            class="border-0 border-r-[1px] border-solid border-grayScale300 pr-3"
            v-if="role === 'admin' && adminBranches?.length > 1"
          >
            <el-select
              v-model="$store.state.branches.active_branch"
              class="w-[180px]"
              @change="branchSelected"
            >
              <el-option
                v-for="branch in adminBranches"
                :key="branch.id"
                :label="branch.title"
                :value="branch.id"
              />
            </el-select>
          </div>
        </div>

        <div class="header-right__logout">
          <button
            class="cursor-pointer flex justify-center items-center w-[30px] h-[30px]"
            @click="logout"
          >
            <i
              class="fa-solid fa-arrow-right-from-bracket text-lg text-white"
            ></i>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { decryptText } from "@/utils/cryptFunc";
import api from "@/apiConfig/api";

const firstName = ref(localStorage.getItem("firstname"));
const lastName = ref(localStorage.getItem("lastname"));
const role = ref(decryptText(localStorage.getItem("role")));
const selectedLanguage = ref(localStorage.getItem("lang") || "uz");
const langOptions = ref([
  { value: "en", label: "En" },
  { value: "ru", label: "Ru" },
  { value: "uz", label: "Uz" },
]);
const adminBranches = ref(
  JSON.parse(localStorage.getItem("admin_branches")) || []
);

const router = useRouter();
const store = useStore();

const logout = () => {
  router.push("/");
  store.commit("auths/logout");
};

const changeLanguage = (lang) => {
  localStorage.setItem("lang", lang);
  selectedLanguage.value = lang;
  window.location.reload();
};

const branchSelected = (val) => {
  localStorage.setItem("branchIdInHeader", val);
  localStorage.setItem("branch_id", val);
  if (role.value === "admin") {
    saveExternalId(val);
  }
};

const saveExternalId = () => {
  const findBranch = adminBranches.value.find(
    (b) => b.id == store.state.branches.active_branch
  );
  if (findBranch) {
    localStorage.setItem("branch_external_id", findBranch.external_id);
    store.commit("setActiveBranchExternalId", findBranch.external_id);
  }
};

onMounted(() => {
  if (role.value === "hunter" || role.value === "finance") {
    store.dispatch("getBranches");
    const branchId = localStorage.getItem("branchIdInHeader");
    if (branchId) {
      store.commit("setActiveBranchId", +branchId);
    }
  }

  if (
    (role.value === "admin" && !adminBranches.value.length) ||
    role.value === "teacher"
  ) {
    store.dispatch("getBranches");
    api.get("/me").then((res) => {
      adminBranches.value = res.data?.branches;
      localStorage.setItem(
        "admin_branches",
        JSON.stringify(res.data?.branches) || []
      );
      store.commit("setActiveBranchId", res.data?.branches[0]?.id);
      store.dispatch("setUserInfo", res.data);
    });
    saveExternalId();
  }

  if (role.value === "admin" && adminBranches.value.length) {
    store.dispatch("getBranches");
    const branchIdInLocalstorage = localStorage.getItem("branch_id");
    const branch_id = branchIdInLocalstorage
      ? +branchIdInLocalstorage
      : adminBranches.value[0]?.id;

    store.commit("setActiveBranchId", branch_id);
  }

  saveExternalId();
});
</script>

<style scoped></style>
